import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const Dashboard = () => import("@/views/dashboard.vue");
const Transactions = () => import("@/views/Transactions.vue");
const login = () => import("@/views/login.vue");
const signup = () => import("@/views/signup.vue");
const send_balance = () => import("@/views/send_balance.vue");
const certificate = () => import("@/views/certificate.vue");
const profit = () => import("@/views/profit.vue");
const settings = () => import("@/views/settings.vue");
const requests = () => import("@/views/Requests.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");

const routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },

  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
    },
  },

  {
    path: "/profit",
    name: "profit",
    component: profit,
    meta: {
      title: "profit",
      requiresAuth: true,
    },
  },
  {
    path: "/certificate",
    name: "certificate",
    component: certificate,
    meta: {
      title: "certificate",
      requiresAuth: true,
    },
  },

  {
    path: "/Transactions",
    name: "Transactions",
    component: Transactions,
    meta: {
      title: "Transactions",
      requiresAuth: true,
    },
  },
  {
    path: "/send_balance",
    name: "send_balance",
    component: send_balance,
    meta: {
      title: "send_balance",
      requiresAuth: true,
    },
  },
  {
    path: "/requests",
    name: "requests",
    component: requests,
    meta: {
      title: "requests",
      requiresAuth: true,
    },
  },

  {
    path: "/signup",
    name: "signup",
    component: signup,
    meta: {
      title: "signup",
    },
  },
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
    },
  },
  { path: "*", component: PageNotFound },
  ,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      next("/Dashboard");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  }
});
