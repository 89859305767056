<template>
  <v-app>
    <AsideMenu
      v-if="$route.name != 'login' && $store.getters.loggedin"
    ></AsideMenu>
    <AppHeader v-if="$store.getters.loggedin && $route.name != 'login' "
      >></AppHeader
    >
    <GuestHeader v-if="!$store.getters.loggedin && $route.name != 'login'"
      >></GuestHeader
    >
    <v-main>
      <router-view />
    </v-main>

    <Footer v-if="$route.name != 'login'">></Footer>

    <v-snackbar
      timeout="600000"
      width="auto"
      right
      v-model="$store.state.connection_snack"
    >
      لايوجد اتصال بالأنترنت
      <template v-slot:action="{ attrs }">
        <v-icon color="gery" v-bind="attrs">wifi_off</v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>
<style scoped></style>
<script>

const AppHeader = () => import("./layout/header.vue");
const AsideMenu = () => import("./layout/AsideMenu.vue");
const GuestHeader = () => import("./layout/guest_header.vue");
const Footer = () => import("./layout/footer.vue");

export default {
  name: "App",
  data: () => ({
    page_keys: {
      pages: [],
    },
  }),
  components: {
    AppHeader,
    AsideMenu,
    Footer,
    GuestHeader,
  },
  watch: {
    $route() {
      this.get_balance();
    },
  },
  methods: {
    get_balance() {
      if (this.$store.getters.loggedin) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: "customer_balance",
              method: "get",
            },
          })
          .then((res) => {
            this.$store.state.balance = res.data.data.balance;
            this.$store.state.block = res.data.data.block;

            this.$store.state.certificates_balance =
              res.data.data.certificates_balance;
          })
          .catch((err) => {
            // console.log(err.response);
          });
      }
    },
  },
  created() {
    this.get_balance();
  },
};
</script>
