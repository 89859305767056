import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";



Vue.use(VueAxios, axios)

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.defaults.baseURL = "https://app.zachi.app/api/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";


// axios.defaults.withCredentials = true;

Vue.config.productionTip = false;



import "@/styles/main.scss";
new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
