import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connection_snack: false,
    def_path: "https://edarra.com/tas2",
    balance: 0,
    certificates_balance: 0,
    block:1,
    user_data: JSON.parse(localStorage.getItem("user_data")) || null,
    token: localStorage.getItem("token_insurance_customer") || null,
    menu: true,
    footer_props: {
      showFirstLastPage: true,
      "items-per-page-text": "لكل صفحة",
      "items-per-page-options": [10, 15, 30],
    },
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    user_data(state, user) {
      state.user_data = user;
    },
    logout(state) {
      state.token = null;
      state.user_data = null;
      localStorage.removeItem("user_data");
      localStorage.removeItem("token_insurance_customer");
    },
    public__request(state, item) {
      state.pages[item.name] = item.data;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data.info;
            localStorage.setItem("token_insurance_customer", token);
            localStorage.setItem("user_data", JSON.stringify(user));
            context.commit("login", token);
            context.commit("user_data", user);
            // console.log(user);
            resolve(res);
            // console.log(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            context.state.connection_snack = false;
            resolve(res);
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
  },
  modules: {},
});
